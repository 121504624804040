
require('../scss/index.scss');
require('./home/home.js');
import AOS from 'aos';
import 'aos/dist/aos.css'; 

// import Swiper JS
import Swiper, { Navigation, Pagination, Lazy } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



// ============================================================================
// onload + show

let onLoad = []; // array of functions

$(window).on('load', function() {
    AOS.init({
        once:true,
    });

    var images = $('img');

    $('img').each(function(){
        var img = $(this).data('src');
        $(this).attr('src', img);
    });

    
    // load functions
    for (let i = 0; i < onLoad.length; i++) {
        onLoad[i]();
    }

    loadParallax();
    updateParallax();

    // make body visible
    

    // STICKY HOME MENU
    $('.template-home .nav-index').css('top', ($('.template-home .nav').outerHeight() - 2 )+ 'px');
    var topHeight = $('.template-home .nav').outerHeight() + $('.nav-index').outerHeight() - 2;
    $(".template-home table").find("thead tr").css('top', topHeight + 'px');

    var headerHeight = $('header').height();
    $('.template-single').css('margin-top', headerHeight+'px');

    Swiper.use([Navigation, Lazy]);

    const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 30,
        observer: true, 
        observeParents: true,       
        freeMode: true,
        watchSlidesProgress: true,
        // Enable lazy loading
        lazy: true,
        loop: true,
        
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      
        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },

        on: {
            init: function () {
              console.log('swiper initialized');
              var currentVideo =  $(".swiper-slide-active").find("video");
              currentVideo.each(function( index ) {
                    this.play();
                    console.log('play');
                });
            },
        },
    });

    /* SWIPER API - Event will be fired after animation to other slide (next or previous) */
    swiper.on('slideChange', function () {
        console.log('slide changed');        
    
        /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
        var prevVideo =  $(".swiper-slide-prev").find("video");
        var nextVideo =  $(".swiper-slide-next").find("video");
        var currentVideo =  $(".swiper-slide-visible").find("video");
        prevVideo.each(function( index ) {
            this.pause();
            this.currentTime = 0;
            console.log('stop');
        });

        nextVideo.each(function( index ) {
            this.pause();
            this.currentTime = 0;
            console.log('stop');
        });
        currentVideo.each(function( index ) {
            this.play();
            console.log('play');
        });
    });

    $('.nav-about__link').on('click', function(){
        var id = $(this).data('id');
        $('.nav-about__link').not(this).removeClass('active');
        $(this).addClass('active')
        $('html, body').animate({
            scrollTop: $("#" + id).offset().top - 179 //Add this
        }, 0);
        var sections = $('section');
        sections.each(function (index){

            if($(window).scrollTop() > $(this).offset().top - 180){
                var id = $(this).attr('id');
                var links = $('.nav-about__link');
                links.each(function (index){ 
                    if($(this).data('id') == id){
                        $(this).addClass('active');
                        $('.nav-about__link').not($(this)).removeClass('active');
                    }
                });

            }
        });
        
    });

    var sections = $('section');
    sections.each(function (index){

        if($(window).scrollTop() > $(this).offset().top - 180){
            var id = $(this).attr('id');
            var links = $('.nav-about__link');
            links.each(function (index){ 
                if($(this).data('id') == id){
                    $(this).addClass('active');
                    $('.nav-about__link').not($(this)).removeClass('active');
                }
            });

        }
    });
    
    
});

$(window).on(
    'touchmove', function(e) { 
        var sections = $('section');
        sections.each(function (index){

            if($(window).scrollTop() > $(this).offset().top - 180){
                var id = $(this).attr('id');
                var links = $('.nav-about__link');
                links.each(function (index){ 
                    if($(this).data('id') == id){
                        $(this).addClass('active');
                        $('.nav-about__link').not($(this)).removeClass('active');
                    }
                });

            }
        });
        }
    );

$(window).scroll(function() {
    updateParallax(); 

    var scrollTop = $(this).scrollTop();
    if($('.template-single__data').length){
        var topDistance = $('.template-single__data').offset().top;
        if($(this).width() > 767){
            if ((topDistance - 75) < scrollTop ) {
                var title = $('.template-single__title-project').find('h1').text();
                $('.nav__name').text(title);
            }
        
            if($(this).scrollTop() === 0) {
                $('.nav__name').empty();
            }
        }
    }
    
    if($('.about-header').length){
        var topDistanceMobile = $('.top-header__logo').offset().top;
        if($(this).width() < 767){
            if((topDistanceMobile + 260) < scrollTop){
                $('.top-header__logo.d-none').addClass('active');
            }

            if($(this).scrollTop() === 0) {
                $('.top-header__logo.d-none').removeClass('active');
            }
        }
    }
    
    var sections = $('section');
    sections.each(function (index){
        if($(window).scrollTop() > $(this).offset().top - 180){
            var id = $(this).attr('id');
            var links = $('.nav-about__link');
            links.each(function (index){ 
                if($(this).data('id') == id){
                    $(this).addClass('active');
                    $('.nav-about__link').not($(this)).removeClass('active');
                }
            });
        }
    });

});

// CLICK ON MENU INDEX/FEATURED

$('.nav-index-sub__link').on('click', function(){
    $(this).addClass('active');
    var id = $(this).attr('id');
    console.log(id);
    $('.' + id).removeClass('d-none');
    setTimeout(function(){
        $('html, body').animate({
            scrollTop: $('.' + id).offset().top - 170 //Add this
        }, 300);
    }, 200, id);
    

    if(id == 'index'){
        $('#grid').removeClass('active');
        $('.grid').addClass('d-none');
    
    } else if(id == 'grid'){
        $('#index').removeClass('active');
        $('.index').addClass('d-none');

    } 
});

$(window).resize(function(){

    // STICKY HOME MENU
    $('.template-home .nav-index').css('top', ($('.template-home .nav').outerHeight() - 2) + 'px');
    var topHeight = $('.template-home .nav').outerHeight() + $('.nav-index').outerHeight() - 2;
    $(".template-home table").find("thead tr").css('top', (topHeight)+ 'px');

    var headerHeight = $('header').height();
    $('.template-single').css('margin-top', headerHeight+'px');

});

// .................................................................................
// PARALLAX EFFECT
// .................................................................................

let parallaxArray = [];

let parallax_items = $('.deco-parallax');

function loadParallax(){

    parallax_items.each(function(i){

        let item = $(this);
    
        if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {
            parallaxArray[i] = parseInt(item.css('top'));

        } else {
            parallaxArray[i] = parseInt(item.css('bottom'));
        }

    });
}

function updateParallax(){

    parallax_items.each(function(i){

        let item = $(this);
        let speed = parseFloat($(this).data('speed'));

        if (!speed) {
            speed = -0.1;
        }

        if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {
            setPropretyParallax(item, speed, 'top', parallaxArray[i]);
        } 
    });
 
}


function setPropretyParallax(element, ratio, value, orig) {
    
    var pos;
   
    pos = orig - ratio * ($(window).scrollTop() - element.offset().top);
    

    pos = pos.toFixed(1);

    element.css(value, pos/10 + 'rem');

}


// FOLLOW CURSOR

$('table').find('tr').on('mousemove', function(e){
    var id = $(this).data('id');
    var offset = $('table').offset().top
    $('.index').find('.image').not('#image-'+id).css('display', 'none');
    let myDiv = $('#image-'+id);
    console.log(id);
    if($(window).width() > 1024){
        if(myDiv !== null){
            console.log('element!')
            myDiv.css('display', 'block');
            if(e.pageX < 300){
                myDiv.css('left', (e.pageX + 250) + "px"); 
            } else {
                myDiv.css('left', (e.pageX - 250) + "px");
            }
    
            myDiv.css('top', (e.pageY) + "px");
            
        }
    }
});



// ajax playlist

$('.playlist-element').on('click', function(){
    var url = $(this).data('url');
    $.ajax({
        url: url,
        type: 'get',
        datatype: "html",
        success: function(response){
            var el = $('.playlist-projects');
            $('.playlist-projects').removeClass('d-none');
            $('.playlist-projects').append(response);
            $('.playlist').addClass('d-none');
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: el.offset().top - 190 //Add this
                }, 300);
            }, 200, el);
        },
        error: function(response){
            console.log(response);
        }

    })

});


